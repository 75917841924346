/*eslint-disable */
import { bus } from '@/main';
const $ = window.jQuery = require('jquery');
import moment from 'moment';
export default {
  name: 'support-chatbot',
  components: {},
  props: [],
  data() {
    return {
      to_user: '',
      from_user: '',
      roomChatId: '',
      submitted: false,
      messages: [],
      userSentDetails: '',
      msgList: [],
      userChatListById: [],
      typingUserInfoState:null,
      selfUserId:''
    }
  },
  computed: {

  },
  mounted() {
    if(localStorage.getItem('LoginedUserID')){
      this.selfUserId = localStorage.getItem('LoginedUserID');
    }
    $(document).ready(function () {
      $(".chat_on").click(function () {
        $('.support_msg').remove()
        $(".Layout").toggle();
        $(".chat_on").hide(300);
        setTimeout(() => {
          $("#messages li span").click(function () {
            console.log("CLick")
            $(this).parent().toggleClass('time_d_shown')
          })
        }, 400)
      });
      $(".chat_close_icon").click(function () {
        $(".Layout").hide();
        $('.Messages_list li').removeClass('time_d_shown')
        $(".chat_on").show(300);
      });
      var elmnt = document.getElementById("chatbot_scroll");
      var y = elmnt.scrollHeight;
      setTimeout(() => {
        $('#chatbot_scroll').scrollTop(y)
      }, 300)
    });
    bus.$on('TypingEventForSelectedUserBus', (data) => {
      this.typingUserInfoState = data
      if (data.message == 'stop') {
        this.typingUserInfoState = '';
      }
    })
  },
  methods: {
    chatRoomCreateFun() {
      localStorage.removeItem('chatUserInfo')
      this.submitted = true
      this.$store.dispatch('getSelfUserInfoApiFun').then((response) => {
        localStorage.setItem('chatUserInfo', JSON.stringify(response.data.data))
        this.userSentDetails = response.data
        this.to_user = response.data.data.id,
          this.from_user = response.data.data.id
        let data = {
          users: [this.to_user],
          is_support_chat: true,
          platform_type:'lbil'
        }
        this.$store.dispatch('createChatRoomForPatientMethod', { data: { id: this.to_user, data: data } }).then((res) => {
          var chatId = res.data.data.id
          this.roomChatId = chatId.replace(/-/g, "")
          this.$store.dispatch('getUserSupportHelpChatDetailsByIdFun', {
            id: chatId
          }).then((res) => {
            this.userChatListById = res.data.data.data.results
            setTimeout(() => {
              var elmnt = document.getElementById("chatbot_scroll");
              var y = elmnt.scrollHeight;
              $('#chatbot_scroll').animate({
                scrollTop: y
              }, 20)
            }, 100)
          }).catch((err) => {})
          var messagesList = document.getElementById('messages');
          let messageAppend = document.getElementById('append_message');
          var chatSocket = new WebSocket(
            'wss://' + process.env.VUE_APP_WEBSOCKET_BASE_URL +
            '/ws/chat/' + this.roomChatId + '/'
          );
          chatSocket.onmessage = function (e) {
            var data = JSON.parse(e.data);
            var message = JSON.parse(data.message);
            var li = document.createElement("li");
            var span = document.createElement("span");
            var chat_data = message.chat_data
            span.appendChild(document.createTextNode(chat_data.message));
            var time = document.createElement("time");
            if(message.chat_data.type == 'recieved'){
              var dateTimeAdd = ['LBIL Admin,' + ' ' + moment(message.chat_data.created_at).format('h:mm a')];
            }else{
              var dateTimeAdd = [moment(message.chat_data.created_at).format('h:mm a')];
            }
            time.appendChild(document.createTextNode(dateTimeAdd));
            time.setAttribute("class", "msg_time");
            li.appendChild(span);
            li.appendChild(time);
            li.setAttribute("class", message.chat_data.type);
            li.classList.add('support_msg');
            messageAppend.appendChild(li);
            var elmnt = document.getElementById("chatbot_scroll");
            var y = elmnt.scrollHeight;
            setTimeout(() => {
              $('#chatbot_scroll').scrollTop(y)
            }, 100)
          };
          chatSocket.onclose = function (e) {
            console.error('Chat socket closed unexpectedly');
          };
          document.querySelector('#chat-message-input').focus();
          document.querySelector('#chat-message-input').onkeyup = function (e) {
            if (e.keyCode === 13) { // enter, return
              document.querySelector('#chat-message-submit').click();
            }
          };
          document.querySelector('#chat-message-submit').onclick = function (e) {
            var messageInputDom = document.querySelector('#chat-message-input');
            var message = messageInputDom.value;
            if (message.length > 0) {
              var sentUserInfo = JSON.parse(localStorage.getItem('chatUserInfo'));
              // console.log(sentUserInfo);
              let sentData = {
                'message': message,
                type: 'sent',
                email: sentUserInfo.email,
                name: sentUserInfo.first_name + ' ' + sentUserInfo.last_name,
                message_id: ''
              }
              chatSocket.send(JSON.stringify({
                'message': message,
                'user': sentUserInfo.id,
                'chat_data': sentData
              }));
              messageInputDom.value = '';
              var elmnt = document.getElementById("chatbot_scroll");
              var y = elmnt.scrollHeight;
              setTimeout(() => {
                $('#chatbot_scroll').scrollTop(y)
              }, 100)
            }
          };
          document.querySelector('#chatSocketClose').onclick = function (e) {
            e.preventDefault();
            chatSocket.close();
            return false;
          };
        }).catch((err) => {})
      })
      return
    }
  }
}