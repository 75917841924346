/*eslint-disable */
import Vue from "vue";
const url = 'wss://'+ process.env.VUE_APP_WEBSOCKET_BASE_URL + '/ws/video-chat/';
let socket;
var checkSocketStatus = false;
var CheckCommonRoomId;
const emitter = new Vue({
  methods: {
    isOpen() {
      let status = '';
      if(socket && socket.readyState) {
        status = socket.readyState;
      }
      return status;
    },
    emit(message) {
      socket.send(JSON.stringify(message));
    },
    send(message) {
      if (1 === socket.readyState) socket.send(message);
    },
    close() {
      checkSocketStatus = true
      if (socket.readyState && 1 === socket.readyState) {
        emitter.$emit("close", JSON.stringify({message: 'Closing Chat', type: 'connectionClosed'}));
        socket.close();
        socket = null; // prevent memory leak
      }
    },
    connect(roomId) {
      var __self = this
      CheckCommonRoomId = roomId
      socket = new WebSocket(url + roomId + "/");
      socket.onmessage = function(msg) {
        let message = JSON.parse(JSON.parse(msg.data).message);
        if(message.type === "forward_message") {
          emitter.$emit("messageForwardedOfUsersNotification", message);
        }
        if(message.type === "messageDeleteForChatOfAll") {
          emitter.$emit("messageDeleteForChatOfAllNotification", message);
        }
        if(message.type === "TypingEventForSelectedUser") {
          emitter.$emit("TypingEventForSelectedUserNotification", message);
        }
        if(message.type === "TypingEventForSelectedGroup") {
          emitter.$emit("TypingEventForSelectedGroupNotification", message);
        }
        if(message.type === "messageIndicationForChat") {
          emitter.$emit("messageIndicationForChatNotification", message);
        }
        if(message.type === "videoCalling") {
          emitter.$emit("videoCallingNotification", message);
        }
        else if(message.type === 'videoCallAccepted') {
          emitter.$emit("videoCallAcceptedNotification", message);
        }
        else if(message.type === 'videoCallDecline') {
          emitter.$emit("videoCallDeclinedNotification", message);
        }
        else if(message.type === 'videoAlertPopupClose') {
          emitter.$emit("videoCallAlertPopupClosed", message);
        }
        else if(message.type === 'videoCallNotrecieved') {
          // emitter.$emit("autoMissedCallEvent", message);
        }
        else if(message.type === 'userLogin') {
          emitter.$emit("userLogin", message);
        }
        else if(message.type === 'userLogout') {
          if(message.videoCallingtypedata){
            emitter.$emit("autoMissedCallEvent", message.videoCallingtypedata);
          }
          emitter.$emit("userLogout", message);
        }
        else if(message.type === 'friendRequest') {
          emitter.$emit("friendRequest", message);
        }
        else if(message.type === 'newMessageNotification') {
          emitter.$emit("newMessageNotification", message);
        }
        else if(message.type === 'newChatMessageOfGroupRoomNotification') {
          emitter.$emit("newChatMessageOfGroupRoomNotification", message);
        }
      };
      socket.onopen = function(event) {
        emitter.$emit("open", event);
        // emitter.$emit("connection", JSON.stringify({message: 'connection created successfully', type: 'connection'}));
      };
      socket.onclose = function (event) {
        if(checkSocketStatus == false){
          // console.error('The Common socket closed has been automatically');
          setTimeout(()=>{
            __self.connect(CheckCommonRoomId)
            // console.log('The Common socket reconnection has been automatically');
          },1000)
        }else{
          // console.log(checkSocketStatus, 'The Common socket connection has been closed successfully');
        }
      };
      socket.onerror = function(err) {
        emitter.$emit("error", err);
      };
    }
  }
});

export default emitter;